import React, {useState, useEffect} from "react";

import vile from "../../images/vile.svg";
import doc from "../../images/document.svg";
import femur from "../../images/femur.svg";

const KitTabs =({children})=>{

  const [activeE, setActiveE] = useState('kit');
  const [initial, setInitial] = useState(false);

  const toggleCurrentActive =(e)=>{
    let tc = e.currentTarget.dataset.id;
    if(tc.length){
      setActiveE(tc);
    }else{
      setActiveE('');
    }
    if(!initial){
      setInitial(true);
    }
    e.preventDefault();
  }

  const getActiveE =(name)=>{
    let cn = name;
    if(cn === activeE){
      return true;
    }else {
      return false;
    }
  }

  const checkActiveClass =(name)=>{
    let cn = name;
    if(cn === activeE){
      return '';
    }else{
      return 'isMuted';
    }
  }

  useEffect(()=>{
    if(initial){
      if(activeE.length){
        let elements = document.getElementsByClassName(activeE);
        let ot = ''
        let iw = typeof window !== "undefined" ? window.innerWidth : '';
        if(iw > 1024){
          window.scrollTo({
            top: elements[0].offsetTop + 340,
            behavior: 'smooth'
          });
        }else{
          window.scrollTo({
            top: elements[0].offsetTop - 50,
            behavior: 'smooth'
          });
        }
      }
    }else{
      return false;
    }
  },[activeE]);

  return(
    <div className="kit-tabs lg_mt-1 mt-1">
      <div className="row kit-tabs__tab-deck small-collapse medium-uncollapse">
        <div className="columns small-12 medium-expand stretch">
          <button data-id="serum" onClick={(e)=>toggleCurrentActive(e)} className={getActiveE('serum') ? `isActive` : ``}>
            Serum tryptase
          </button>
        </div>
        <div className="columns small-12 medium-expand stretch mt-1">
          <button data-id="kit" onClick={(e)=>toggleCurrentActive(e)} className={getActiveE('kit') ? `isActive` : ``}>
            KIT D816V peripheral blood
          </button>
        </div>
        <div className="columns small-12 medium-expand stretch mt-1">
          <button data-id="biopsy" onClick={(e)=>toggleCurrentActive(e)} className={getActiveE('biopsy') ? `isActive` : ``}>
            Bone marrow biopsy
          </button>
        </div>
      </div>
      <div className="row kit-tabs__icon-deck lg_mt-4 mt-2">
        <div className="columns small-12 medium-expand">
          {getActiveE('serum') &&
            <div className="icon">
              <img src={doc}/>
            </div>
          }
        </div>
        <div className="columns small-12 medium-expand">
          {getActiveE('kit') &&
            <div className="icon">
              <img src={vile}/>
            </div>
          }
        </div>
        <div className="columns small-12 medium-expand">
          {getActiveE('biopsy') &&
            <div className="icon">
              <img src={femur}/>
            </div>
          }
        </div>
      </div>
      <div className="row kit-tabs__tabs-deck">

        {getActiveE('serum') &&
          <>
          {/* tabby */}
          <div className="columns serum">
            <div className="purple-card">
              <p className="h5 mb-0 lg_mt-4 mt-3 strong text-center">Measure serum tryptase</p>
              <p className="h5 lg_mt-2 mt-2">When patients present with SM symptoms in the areas of skin, gastrointestinal, respiratory, systemic, cardiovascular, neuropsychiatric, or musculoskeletal systems, be sure to run a tryptase test. The level will help direct you to the appropriate next steps.<sup>5</sup></p>
              <p className="h5 lg_mt-2 mt-2">The measurement of serum tryptase is a diagnostic approach that can be used as a diagnostic marker in SM and help to distinguish between categories of the disease. When serum total tryptase persistently exceeds 20 ng/mL (a WHO minor criterion), results should be interpreted alongside full clinical and lab findings.<sup>3,5</sup>*</p>
              <div className="row lg_mt-4 mt-3">
                <div className="columns text-center small-12 medium-expand">
                  <p className="text--watch-quinn h1--xl mb-0">&lt;11.5</p>
                  <p className="text--watch-quinn h2 mb-0">ng/mL</p>
                  <p className="h5 mb-0 lg_mt-1 mt-1">Mastocytosis unlikely, but <br className="hide-for-medium"/>cannot be ruled out<sup>5,6</sup></p>
                </div>
                <div className="columns relative stretch large-1 mt-2">
                  <div className="hr hr hide-for-medium"></div>
                  <div className="vr vr show-for-medium"></div>
                </div>
                <div className="columns text-center small-12 medium-expand mt-2">
                  <p className="text--watch-quinn h1--xl mb-0">11.5-20</p>
                  <p className="text--watch-quinn h2 mb-0">ng/mL</p>
                  <p className="h5 mb-0 lg_mt-1 mt-1">Systemic mastocytosis possible—screen for KIT D816V in peripheral blood with high-sensitivity assay<sup>5</sup></p>
                </div>
                <div className="columns relative stretch large-1 mt-2">
                  <div className="hr hr hide-for-medium"></div>
                  <div className="vr vr show-for-medium"></div>
                </div>
                <div className="columns text-center small-12 medium-expand mt-2">
                  <p className="text--watch-quinn h1--xl mb-0">&ge;20</p>
                  <p className="text--watch-quinn h2 mb-0">ng/mL</p>
                  <p className="h5 mb-0 lg_mt-1 mt-1">Bone marrow biopsy and screen for KIT D816V mutation<sup>5</sup></p>
                </div>
              </div>
              <div className="row lg_mt-3 mt-2">
                <div className="columns">
                  <ul className="hanging-ref color--white">
                    <li><span className="ref">*</span>Unless there is an associated myeloid neoplasm, in which case this parameter is not valid.<sup>3</sup></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* tabby */}
          </>
        }

        {getActiveE('kit') &&
          <>
          {/* tabby */}
          <div className="columns kit">
            <div className="purple-card">
              <p className="h5 mb-0 lg_mt-4 mt-3 strong text-center">KIT D816V testing is a critical part of the diagnostic workup</p>
              <p className="h5 lg_mt-2 mt-2">It is recommended by ECNM-AIM to screen for KIT D816V in patients with suspected SM as a first step.<sup>1</sup></p>
              <p className="h5 lg_mt-2 mt-2">A highly sensitive PCR-based method (eg, ASO-qPCR or ddPCR with &lt;1% sensitivity via VAF) can be used to screen for the presence of KIT D816V in peripheral blood.<sup>1</sup></p>
              <p className="h5 lg_mt-2 mt-2">Patients who test positive for KIT D816V using high-sensitivity KIT testing have an increased likelihood of SM. Initiate further workup to assess if the patient meets WHO criteria for SM diagnosis (see below for full WHO criteria).<sup>1-3</sup></p>
              <p className="h5 lg_mt-2 mt-2">If KIT D816V test is negative and/or basal serum tryptase is &lt;11.5 ng/mL in patients with high clinical suspicion of SM, bone marrow biopsy is critical to rule out SM.<sup>1,4,5</sup></p>
            </div>
          </div>
          {/* tabby */}
          </>
        }

        {getActiveE('biopsy') &&
          <>
          {/* tabby */}
          <div className="columns biopsy">
            <div className="purple-card">
              <p className="h5 mb-0 lg_mt-4 mt-3 strong text-center">Complete a bone marrow biopsy</p>
              <p className="h5 lg_mt-2 mt-2">Bone marrow is the most common site of extracutaneous accumulation of mast cells in patients with SM.<sup>3,5</sup></p>
              <p className="h5 lg_mt-2 mt-2">The presence of certain clinical/laboratory features supports bone marrow investigation when SM is suspected in adult patients.<sup>7</sup></p>
              <p className="h5 lg_mt-2 mt-2">Bone marrow evaluation should look out for multifocal dense infiltrates of mast cells (≥15 mast cells in aggregates) as a marker in SM. Aberrant mast cells in bone marrow express CD25, with or without CD2, <br className="show-for-medium"/>in addition to normal mast cell markers apparent in SM. Specialty pathologist expertise may be required.<sup>3</sup></p>
              <p className="h5 lg_mt-2 mt-2">High-sensitivity peripheral blood KIT D816V testing may aid in identifying patients who should have a bone marrow investigation when SM is suspected in adult patients.<sup>1</sup></p>
            </div>
          </div>
          {/* tabby */}
          </>
        }

      </div>
    </div>
  );
}

export default KitTabs;
