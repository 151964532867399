import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReferenceBlock from "../components/template-partials/reference-block";
import FooterCrown from "../components/template-partials/footer-crown";
import KitTabs from "../components/template-partials/kit-tabs";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import neuron from "../images/neuron.png";

import dykKit from "../images/dyk-kit.png";
import dykKitBG from "../images/dyk-kit-bg.png";

import pdfDownload from "../images/pdfdownload.svg";

const TestKitPage = () => (
  <Layout route="test-kit">
    <Seo page="test-kit" title="Test for KIT D816V1" description="Suspect systemic mastocytosis? Test for the KIT D816V mutation. Access information about laboratories that offer high-sensitivity KIT D816V testing." />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-11 large-9">
        <h1 className="color--purple text--watch-quinn">Once you suspect SM, test for <br className="show-for-large"/>KIT D816V<sup>1</sup></h1>
        <ul className="hanging-ref hanging-ref--no-ref">
          <li>Additional testing is required for diagnosis and subtyping.</li>
        </ul>
      </div>
    </div>

    <div id="find-a-lab" className="row align-middle lg_mt-4 mt-3">
      <div className="columns small-11 large-10">
        <div className="row small-collapse medium-uncollapse">
          <div className="columns">
            <div className="purple-card text-left purple-card--left purple-card--bottom-right-img purple-card--bottom-right-img--neuron" style={{backgroundImage : `url(${neuron})`, backgroundSize: `230px 170px`}}>
            <p className="h3 mb-0 lg_mt-2 mt-2">Access high-sensitivity KIT D816V testing</p>
            <p className="h4 lg_mt-2 mt-2">It is critical to use high-sensitivity KIT D816V assays, <br className="show-for-medium"/>as low-sensitivity assays may fail to detect the mutation.<sup>1</sup></p>
            <p className="h5 lg_mt-2 mt-2">Blueprint Medicines sponsors high-sensitivity (&lt;1 %) KIT D816V testing <br className="show-for-medium"/>at <strong>no charge* for eligible patients</strong>.<sup>&dagger;</sup></p>
            <a href="https://oncology.labcorp.com/blueprintsm" target="_blank" rel="noreferrer" className="btn btn--rose btn--small-fw lg_mt-2 mt-2">LEARN MORE</a>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-6 mt-3">
      <div className="columns">
        <p className="h3 strong color--purple">Please see important <br className="hide-for-large"/> details below:</p>
        <div className="row lg_mt-3 mt-2 align-center medium-align-left">
          <div className="columns small-11 medium-expand">
            <ul className="ul-standard ul-standard--lg text--regular">
              <li>While Blueprint provides financial support for this program, testing services are performed by an independent third party and Blueprint Medicines assumes no liability and provides no warranties for the testing services provided by independent third parties</li>
              <li>This program is subject to eligibility criteria available at <a className="a--std" href="https://oncology.labcorp.com/blueprintsm" target="_blank" rel="noreferrer">oncology.labcorp.com/blueprintsm</a></li>
              <li>Healthcare professionals shall use independent medical judgment in determining whether patients meet the program criteria for participation</li>
              <li>No identifiable patient data will be shared with Blueprint Medicines as part of this program</li>
              <li>This program is available in the United States only</li>
              <li>Healthcare professionals and patients who use this program have no obligation to recommend, purchase, order, prescribe, promote, administer, use, or support any Blueprint Medicines or Labcorp product or service</li>
              <li>No payers, including government payers, are billed for this program</li>
            </ul>
          </div>
        </div>
        <div className="row align-center medium-align-left">
          <div className="columns small-11 medium-expand">
            <ul className="hanging-ref lg_mt-4 mt-2">
              <li><span className="ref">*</span>Terms and conditions including eligibility criteria apply. The KIT D816V test will be provided at no cost to patients, healthcare providers, and payers through this program. Excludes office visit, sample collection for tests not associated with this program, and any other related costs to patients. Labcorp will not bill the eligible patient’s insurance for a KIT D816V test; however, Labcorp will bill selected payer(s) for other testing services ordered.</li>
              <li><span className="ref">&dagger;</span>Terms and conditions apply. Testing performed by Labcorp. While Blueprint Medicines provides financial support, Blueprint Medicines assumes no liability and provides no warranties for the testing services provided by independent third parties. See Labcorp website <a className="a--std" href="https://oncology.labcorp.com/blueprintsm" target="_blank" rel="noreferrer">oncology.labcorp.com/blueprintsm</a> for full program details and program eligibility.</li>
              <li><span className="ref">&nbsp;</span>SM=systemic mastocytosis.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-6 mt-4 mb-3 pt-4 lg_mb-6 align-middle coldec coldec--vertical-decoration align-center medium-align-left">
      <span className="decore"></span>
      <div className="columns small-11 large-6">
        <p className="h3 color--purple sb">
          High-sensitivity (&lt;1%) KIT D816V testing is also available through other independent laboratories
        </p>
      </div>
      <div className="columns small-11 large-6 mt-2">
        <div className="row align-center">
          <div className="columns large-8">
            <div className="h5 lg_mt-2 mt-2">
              <p className="h4 color--purple">Find a lab offering high-sensitivity KIT D816V assays</p>
              {/* target-fix class disables pointer events on children of <a> tags*/}
              <a className="strong lg_mt-1 mt-1 btn btn--pdf btn--small-fw target-fix no-ext" href="/kit-testing-commercial-lab-sheet.pdf" target="_blank" rel="noreferrer"><span>SEE RESOURCE&nbsp;</span><img alt="pdf download" src={pdfDownload} style={{transform: `translateY(-2px)`}} width="50" height="auto"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-2 small-collapse medium-uncollapse">
      <div className="columns">
        <div className="hero-breaker hero-breaker--progress" style={{"--bg-image": `url(${dykKitBG})`}}>
          <div className="row align-middle small-col-reverse">
            <div className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--kit small-12 large-5" style={{"--bg-image": `url(${dykKit})`}}>
              <span className="visually-hidden">Woman coughing</span>
            </div>
            <div className="columns small-12 large-7 text-right mt-4">
              <div className="row">
                <div className="columns large-10">
                  <p className="color--purple text--watch-quinn h2">Did you know?</p>
                  <p className="color--purple h2 lead">Performing a high-sensitivity <br className="hide-for-medium"/>KIT D816V <br className="show-for-medium"/>test can help confirm SM<sup>1</sup></p>
                  <AnchorLink to="/is-it-sm/#case-studies" title="Case studies" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">
                    REVIEW CASE STUDIES
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="diagnose-sm" className="row lg_mt-6 mt-3 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <p className="h2 strong color--purple">A thorough workup that may include a high-sensitivity KIT D816V assay is required for definitive diagnosis<sup>1</sup></p>
      </div>
    </div>

    <div>
      <KitTabs/>
    </div>

    <div className="row lg_mt-7 mt-4 small-collapse medium-uncollapse">
      <div className="columns small-11 large-8">
        <div className="rose-card rose-card--left rose-card--left--indent text-left">
          <p className="h3 mb-0 lg_mt-2 mt-1 strong">4th edition World Health Organization (WHO) diagnostic criteria for SM</p>
        </div>
      </div>
    </div>

    <div className="row align-right small-collapse medium-uncollapse">
      <div className="columns small-11 large-11">
        <div className="callout-rounded callout-rounded--grey callout-rounded--right">

          <div className="row">
            <div className="columns large-11">
              <p className="color--purple h3">Diagnosis of SM requires the presence of 1 major criterion and at least 1 minor criterion, or at least 3 minor criteria<sup>3</sup></p>
            </div>
          </div>

          <div className="row lg_mt-3 mt-3 align-middle">
            <div className="columns small-12 large-4">
              <p className="color--purple h3 strong">Major criterion</p>
            </div>
            <div className="columns">
              <p className="color--purple h5">Multifocal aggregates of ≥15 mast cells in bone marrow sections and/or other extracutaneous organ(s)</p>
            </div>
          </div>

          <div className="row lg_mt-2 mt-2">
            <div className="columns">
              <div className="hr"></div>
            </div>
          </div>

          <div className="row lg_mt-3 mt-3 align-middle">
            <div className="columns small-12 large-4">
              <p className="color--purple h3 strong">Minor criteria</p>
            </div>
            <div className="columns">
              <ul className="hanging-ref h5 color--purple">
                <li><span className="ref">1</span>In biopsy sections of bone marrow or other extracutaneous organs, &gt;25% of mast cells in the infiltrate are spindle-shaped or have atypical morphology; or &gt;25% of all mast cells in bone marrow aspirate smears are immature or have atypical features</li>
                <li className="lg_mt-1 mt-1"><span className="ref">2</span>Presence of activating point mutation at codon 816 of KIT in bone marrow, blood, or other extracutaneous organ</li>
                <li className="lg_mt-1 mt-1"><span className="ref">3</span>Mast cells in bone marrow, blood, or other extracutaneous organs express CD25, with or without CD2, in addition to normal mast  cell markers</li>
                <li className="lg_mt-1 mt-1"><span className="ref">4</span>Serum total tryptase persistently &gt;20 ng/mL (if patient has an associated myeloid neoplasm, this parameter is not valid)</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className="row lg_mt-10 mt-10 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-11 medium-expand text-right">
        <p className="h4 color--purple">Get information and updates <br className="show-for-large"/>delivered to your inbox</p>
        <Link className="btn btn--outline btn--icon uppercase mt-1" to="/resources/">RESOURCES <FontAwesomeIcon className="color--powder" icon={faAngleRight}/></Link>
      </div>
    </div>

    <FooterCrown type="test"/>

    <ReferenceBlock>
      <ol className="color--purple">
        <li>Hoermann G et al. <em>J Allergy Clin Immunol Pract.</em> 2022;10(8):1953-1963.</li>
        <li>Garcia-Montero AC et al. <em>Blood.</em> 2006;108(7):2366-2372.</li>
        <li>Pardanani A. <em>Am J Hematol.</em> 2021;96(4):508-525.</li>
        <li>Valent P et al. <em>J Allergy Clin Immunol Pract.</em> 2022;10(8):1999-2012.e6.</li>
        <li>Theoharides TC et al. <em>N Engl J Med.</em> 2015;373(2):163-172.</li>
        <li>Akin C, Valent P. <em>Immunol Allergy Clin North Am.</em> 2014;34(2):207-218.</li>
        <li>Valent P et al. <em>Int J Mol Sci.</em> 2019;20(12):2976.</li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default TestKitPage
